import React from 'react';

import classes from './DetailedMessage.module.scss';

const DetailedMessage = (props) => {
  const { data } = props;
  const { name, email, date, message } = data;

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.nameAndEmail}>
          <h2 className={classes.name}>{name}</h2>
          <p className={classes.date}>{date}</p>
        </div>
        <a href={`mailto:${email}`} className={[classes.email]}>
          {email}
        </a>
      </div>
      <p className={classes.message}>{message}</p>
    </div>
  );
};

export default DetailedMessage;
