import React, { useEffect } from 'react';

import classes from './Modal.module.scss';

const Modal = (props) => {
  const { children, closeModalFn } = props;

  useEffect(() => {
    const closeOnEscKeyPress = (e) => {
      if (e.charCode || e.keyCode === 27) {
        closeModalFn();
      }
    };

    window.addEventListener('keydown', closeOnEscKeyPress);

    return function cleanup() {
      window.removeEventListener('keydown', closeOnEscKeyPress);
    };
  }, [closeModalFn]);

  return (
    <div className={classes.container}>
      <span className={classes.closeButton} onClick={closeModalFn}>
        Close
      </span>
      {children}
    </div>
  );
};

export default Modal;
