import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import classes from './Summary.module.scss';

const Summary = (props) => {
  const { data, handleClick } = props;

  return (
    <>
      {data.map((data) => (
        <div key={uuidv4()} className={classes.message} onClick={() => handleClick(data)}>
          <div className={classes.overlay}></div>
          <div className={classes.header}>
            <div className={classes.nameAndEmail}>
              <h6 className={classes.name}>{data.name}</h6>
              <p className={[classes.date]}>{data.date}</p>
            </div>
            <a href={`mailto:${data.email}`} className={[classes.email]}>
              {data.email}
            </a>
          </div>
          <div className={classes.content}>
            <p>{data.message}</p>
          </div>
        </div>
      ))}
    </>
  );
};

export default Summary;
