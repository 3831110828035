import React from "react";
import classes from "./Logo.module.scss";

const Logo = (props) => {
  const customClass = props.class ? props.class : "";
  return (
    <div className={`${classes.container} ${customClass}`} style={props.style}>
      <p className={classes.logo}>JG</p>
    </div>
  );
};

export default Logo;
