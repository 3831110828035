import React, { useState } from 'react';

import 'react-day-picker/lib/style.css';
import { v4 as uuidv4 } from 'uuid';

import classes from './AddHealthInfo.module.scss';
import PresentationalCard from '../../PresentationalCard/PresentationalCard';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import Input from '../Input/Input';
import Button from '../../Button/Button';
import { db } from '../../../firebase';

const AddHealthInfo = (props) => {
  const { heading, closeModalFn, data, type = 'add' } = props;
  console.log(data);

  const [date, setDate] = useState(data?.date ? new Date(data?.date.toDate()) : new Date());
  const [loading, setLoading] = useState(false);
  const [bloodPressureInfo, setBloodPressureInfo] = useState({
    lower: data?.bloodPressure?.lower ?? '',
    upper: data?.bloodPressure?.upper ?? '',
    pulse: data?.bloodPressure?.pulse ?? '',
    time: data?.bloodPressure?.time ?? '',
  });
  const [sugarInfo, setSugarInfo] = useState({
    afterDinner: data?.sugar?.afterDinner ?? '',
    beforeDinner: data?.sugar?.beforeDinner ?? '',
    afterLunch: data?.sugar?.afterLunch ?? '',
    beforeLunch: data?.sugar?.beforeLunch ?? '',
    fastingBloodSugar: data?.sugar?.fastingBloodSugar ?? '',
    postPrandialBloodSugar: data?.sugar?.postPrandialBloodSugar ?? '',
  });

  const handleDateChange = (date) => {
    setDate(date);
  };

  const handleSugarInputChange = (e) => {
    const { target } = e;

    setSugarInfo({
      ...sugarInfo,
      [target.name]: target.value,
    });
  };

  const handleBloodPressureInputChange = (e) => {
    const { target } = e;

    setBloodPressureInfo({
      ...bloodPressureInfo,
      [target.name]: target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const healthInfo = {
      date,
      bloodPressure: {
        ...bloodPressureInfo,
      },
      sugar: {
        ...sugarInfo,
      },
    };

    try {
      setLoading(true);

      type === 'edit'
        ? await db.collection('health').doc(data.id).update(healthInfo)
        : await db.collection('health').doc(uuidv4()).set(healthInfo);
      setLoading(false);
      closeModalFn();

      console.log('success');
    } catch (error) {
      setLoading(false);
      console.error(error.message);
    }
  };

  return (
    <PresentationalCard>
      <form onSubmit={handleSubmit}>
        <h3 className={classes.heading}>{heading}</h3>
        <Input
          label='Select Date'
          name='date'
          value={date}
          onChange={handleDateChange}
          isDatePicker
        />
        <h5 className={classes.sectionHeading}>Sugar</h5>
        <div className={classes.sugarSection}>
          <Input
            value={sugarInfo.fastingBloodSugar}
            onChange={(e) => handleSugarInputChange(e)}
            label='Fasting Blood Sugar'
            name='fastingBloodSugar'
            autoComplete="off"
          />
          <Input
            value={sugarInfo.postPrandialBloodSugar}
            onChange={(e) => handleSugarInputChange(e)}
            label='After Breakfast'
            name='postPrandialBloodSugar'
            autoComplete="off"
          />
          <Input
            value={sugarInfo.beforeLunch}
            onChange={(e) => handleSugarInputChange(e)}
            label='Before Lunch'
            name='beforeLunch'
            autoComplete="off"
          />
          <Input
            value={sugarInfo.afterLunch}
            onChange={(e) => handleSugarInputChange(e)}
            label='After Lunch'
            name='afterLunch'
            autoComplete="off"
          />
          <Input
            value={sugarInfo.beforeDinner}
            onChange={(e) => handleSugarInputChange(e)}
            label='Before Dinner'
            name='beforeDinner'
            autoComplete="off"
          />
          <Input
            value={sugarInfo.afterDinner}
            onChange={(e) => handleSugarInputChange(e)}
            label='After Dinner'
            name='afterDinner'
            autoComplete="off"
          />
        </div>
        <h5 className={classes.sectionHeading}>Blood Pressure</h5>
        <div className={classes.bloodPressureSection}>
          <Input
            value={bloodPressureInfo.time}
            onChange={(e) => handleBloodPressureInputChange(e)}
            label='Time'
            name='time'
            autoComplete="off"
          />
          <Input
            value={bloodPressureInfo.pulse}
            onChange={(e) => handleBloodPressureInputChange(e)}
            label='Pulse'
            name='pulse'
            autoComplete="off"
          />
          <Input
            value={bloodPressureInfo.upper}
            onChange={(e) => handleBloodPressureInputChange(e)}
            label='Upper'
            name='upper'
            autoComplete="off"
          />
          <Input
            value={bloodPressureInfo.lower}
            onChange={(e) => handleBloodPressureInputChange(e)}
            label='Lower'
            name='lower'
            autoComplete="off"
          />
        </div>
        <Button disabled={loading}>{loading ? <LoadingSpinner /> : 'Submit'}</Button>
      </form>
    </PresentationalCard>
  );
};

export default AddHealthInfo;
