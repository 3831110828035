import React from "react";
import classes from "./TextInput.module.scss";

const TextInput = (props) => {
  const { placeholder, type, name, value } = props;

  return (
    <input
      className={classes.input}
      type={type}
      name={name}
      value={value}
      placeholder={placeholder}
      onChange={(e) => props.onChange(e, name)}
    />
  );
};

export default TextInput;
