import React from "react";
import SectionHeading from "../SectionHeading/SectionHeading";
import Skill from "./Skill/Skill";
import classes from "./Skills.module.scss";

const Skills = () => {
  return (
    <div id="skills" className={`contain ${classes.container}`}>
      <SectionHeading>Skills</SectionHeading>
      <div className={classes.skills}>
        <Skill img="html-5.png" skill="HTML" />
        <Skill img="css3.png" skill="CSS" />
        <Skill img="sass.png" skill="Sass" />
        <Skill img="js.png" skill="JavaScript" />
        <Skill img="jquery.png" skill="jQuery" />
        <Skill img="react.png" skill="React JS" />
        <Skill img="react.png" skill="React Native" />
        <Skill img="git.png" skill="Git" />
        <Skill img="adobe-xd.png" skill="Adobe XD" />
      </div>
    </div>
  );
};

export default Skills;
