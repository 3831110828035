import React from 'react';

import classes from './LandingPage.module.scss';
import Navbar from '../Navigation/Navbar/Navbar';
import Banner from './Banner/Banner';
import LargeName from './LargeName/LargeName';
import Work from '../Work/Work';
import Skills from '../Skills/Skills';
import Contact from '../Contact/Contact';
import Footer from '../Footer/Footer';
import Testimonials from '../Testimonials/Testimonials';
import Clients from '../Clients/Clients';

const LandingPage = () => {
  return (
    <>
      <Navbar />
      <div id='home' className={classes.container}>
        <div className={classes.banner}>
          <div className={classes.largeNameContainer}>
            <div className={classes.largeName}>
              <LargeName />
            </div>
          </div>
          <Banner />
        </div>
        <Work />
        <Skills />
        <Testimonials />
        {/* <Clients /> */}
        <Contact />
        <Footer />
      </div>
    </>
  );
};

export default LandingPage;
