import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import './App.scss';
import { AuthProvider } from './context/AuthContext';
import LandingPage from './components/LandingPage/LandingPage';
import Inbox from './components/Inbox/Inbox';
import SignIn from './components/SignIn/SignIn';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import HealthPage from './components/HealthPage/HealthPage';

function App() {
  // Prevent users from downloading pictures on right click.
  // This function prevents the right click menu from opening
  useEffect(() => {
    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });
  }, []);

  return (
    <Router>
      <AuthProvider>
        <Switch>
          <Route exact path='/' component={LandingPage} />
          <PrivateRoute path='/inbox' component={Inbox} />
          <PrivateRoute path='/my-healthy-body-haha' component={HealthPage} />
          <Route path='/get-inside' component={SignIn} />
        </Switch>
      </AuthProvider>
    </Router>
  );
}

export default App;
