import React from 'react';
import classes from './Card.module.scss';

const Card = (props) => {
  const { name, designation, company, testimony, customClass } = props;
  return (
    <div className={`${classes.container} ${customClass}`}>
      <div>
        <div className={classes.profileDetails}>
          <p className={classes.name}>{name}</p>
          <p className={classes.company}>
            {designation}, {company}
          </p>
        </div>
      </div>
      <div className={classes.testimony}>{testimony}</div>
    </div>
  );
};

export default Card;
