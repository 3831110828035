import React from "react";
import classes from "./Skill.module.scss";

const Skill = (props) => {
  return (
    <div className={classes.container}>
      <div className={classes.image}>
        <img src={require(`../../../assets/images/${props.img}`)} alt="" />
      </div>
      <p className={classes.skillLabel}>{props.skill}</p>
    </div>
  );
};

export default Skill;
