import React from 'react';
import { Link } from 'react-scroll';

import classes from './ProfileButton.module.scss';

const ProfileButton = () => {
  return (
    <div className={classes.container}>
      <Link to={'work'} smooth={true} duration={1000}>
        VIEW PROFILE
        <span>
          <img src={require('../../../../assets/images/down-arrow.svg')} alt='Down Arrow' />
        </span>
      </Link>
    </div>
  );
};

export default ProfileButton;
