import React, { useEffect, useState } from 'react';
import { db } from '../../firebase';

import classes from './Inbox.module.scss';
import Summary from './Summary/Summary';
import DetailedMessage from './DetailedMessage/DetailedMessage';
import LandingPageSpinner from '../LoadingSpinner/LandingPageSpinner/LandingPageSpinner';
import SignOut from '../SignOut/SignOut';

const Inbox = () => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectedMessage, setSelectedMessage] = useState({
    name: '',
    email: '',
    message: '',
    date: '',
  });

  useEffect(() => {
    getMessagesFromDb();
  }, []);

  const getMessagesFromDb = async () => {
    setLoading(true);
    try {
      const data = await db.collection('contacts').orderBy('date', 'desc').get();
      const messagesInDb = data.docs.map((doc) => doc.data());

      const messagesWithProperDate = messagesInDb.map((item) => {
        const date = item.date.toDate().toDateString();

        return {
          ...item,
          date,
        };
      });

      setMessages(messagesWithProperDate);
      setSelectedMessage(messagesWithProperDate[0]);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };

  const handleClick = (data) => {
    setSelectedMessage(data);
  };

  return (
    <div className={classes.container}>
      {loading ? (
        <LandingPageSpinner />
      ) : (
        <>
          <SignOut />
          <div className={classes.summary}>
            <Summary data={messages} handleClick={handleClick} />
          </div>
          <div className={classes.messageInDetail}>
            <DetailedMessage data={selectedMessage} />
          </div>
        </>
      )}
    </div>
  );
};

export default Inbox;
