import React, { useState, useEffect } from 'react';
import classes from './Contact.module.scss';
import SectionHeading from '../SectionHeading/SectionHeading';
import TextInput from '../TextInput/TextInput';
import Button from '../Button/Button';
import { db } from '../../firebase';

const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: '',
  });
  const [errors, setErrors] = useState({
    firstNameError: '',
    lastNameError: '',
    emailError: '',
    messageError: '',
  });

  const { firstName, lastName, email, message } = formData;

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validate = () => {
    let validateErrors = {
      firstNameError: '',
      lastNameError: '',
      emailError: '',
      messageError: '',
    };

    let { firstNameError, lastNameError, emailError, messageError } = validateErrors;

    if (firstName.length < 1) {
      firstNameError = 'Please enter your first name';
    }
    if (lastName.length < 1) {
      lastNameError = 'Please enter your last name';
    }
    if (email.length < 1) {
      emailError = 'Please enter a valid email id';
    }
    if (message.length < 1) {
      messageError = 'Please enter a message';
    }
    if (firstNameError || lastNameError || emailError || emailError) {
      validateErrors = {
        firstNameError,
        lastNameError,
        emailError,
        messageError,
      };

      setErrors(validateErrors);
      console.log(validateErrors);
      return false;
    }

    return true;
  };

  const clearForm = () => {
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      message: '',
    });

    setErrors({
      firstNameError: '',
      lastNameError: '',
      emailError: '',
      messageError: '',
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValid = validate();

    if (isValid) {
      await db
        .collection('contacts')
        .add({
          name: firstName + ' ' + lastName,
          email,
          message,
          date: new Date(),
        })
        .then(() => {
          alert('I will get in touch with you as soon as possible');
        })
        .catch((err) => {
          alert(err);
        });

      clearForm();
    }
  };

  return (
    <div id='contact' className={classes.container}>
      <div className='contain'>
        <SectionHeading style={{ padding: '0 0 32px 0' }}>Let's talk</SectionHeading>
        <form onSubmit={handleSubmit}>
          <div className={classes.formMetaDataContainer}>
            <div className={classes.formMetaData}>
              <TextInput
                onChange={(e) => handleChange(e)}
                name='firstName'
                placeholder='First Name'
                type='text'
                value={firstName}
              />
              <p className={classes.error}>{errors.firstNameError}</p>
              <TextInput
                onChange={(e) => handleChange(e)}
                name='lastName'
                placeholder='Last Name'
                type='text'
                value={lastName}
              />
              <p className={classes.error}>{errors.lastNameError}</p>
              <TextInput
                onChange={(e) => handleChange(e)}
                name='email'
                placeholder='Email'
                type='text'
                value={email}
              />
              <p className={classes.error}>{errors.emailError}</p>
            </div>
            <div className={classes.message}>
              <textarea
                onChange={(e) => handleChange(e)}
                name='message'
                id='message'
                placeholder='Leave me a message . . .'
                value={message}
              ></textarea>
              <p className={classes.error}>{errors.messageError}</p>
            </div>
          </div>
          <div className={classes.submitBtn}>
            <Button type='submit'>Send</Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
