import React from "react";
import classes from "./Footer.module.scss";
import SocialIcon from "../SocialIcon/SocialIcon";

const Footer = (props) => {
  return (
    <div className={classes.container}>
      <h5 className={classes.heading}>Find me here too</h5>
      <div className={classes.SocialIcons}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/iamjoelgeorge/"
        >
          <SocialIcon img="socialMedia/instagram.svg" />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://dribbble.com/iamjoelgeorge"
        >
          <SocialIcon img="socialMedia/dribbble.svg" />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://in.linkedin.com/in/iamjoelgeorge"
        >
          <SocialIcon img="socialMedia/linkedin.svg" />
        </a>
      </div>
    </div>
  );
};

export default Footer;
