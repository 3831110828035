import React from "react";
import classes from "./Button.module.scss";

const Button = (props) => {
  const customClass = props.class ? props.class : "";
  return (
    <button
      type={props.type}
      className={`${classes.container} ${customClass}`}
      style={props.style}
    >
      {props.children}
    </button>
  );
};

export default Button;
