import { useEffect, useRef, useState } from 'react';

import { useInfiniteScroll } from './useInfiniteScroll';

const INFINITE_SCROLL_CARD_LIMIT = 10;

export const useFetchData = ({ PAGE_LIMIT = INFINITE_SCROLL_CARD_LIMIT, query }) => {
  const [pages, setPages] = useState([[]]);
  const [fetching, setFetching, setHasMore] = useInfiniteScroll({ fetching: true, hasMore: true });
  const listeners = useRef([]);

  useEffect(() => () => listeners.current.map((listener) => listener()), [listeners]);

  useEffect(() => {
    if (!fetching) {
      return;
    }

    const length = pages.length,
      page = pages[length - 1] || [],
      postLast = page[page.length - 1];

    let newQuery = query;
    if (0 < length) newQuery = query.startAfter(postLast?.date || new Date(253402300799999));

    let mounted = true;

    newQuery
      .limit(PAGE_LIMIT)
      .get()
      .then((snapshot) => {
        if (mounted) {
          const posts = snapshot.docs.map((doc) => doc.data());
          setPages((prevState) => [...prevState, posts]);

          const unsubscribe = newQuery
            .endAt(posts[posts.length - 1]?.date || new Date())
            .onSnapshot((snapshot) => {
              const postsUpdated = snapshot.docs.map((doc) => {
                return {
                  id: doc.id,
                  ...doc.data(),
                };
              });
              setPages((prevState) => prevState.map((b, i) => (i === length ? postsUpdated : b)));
            });

          listeners.current = [...listeners.current, unsubscribe];

          if (posts.length < PAGE_LIMIT) setHasMore(false);

          setFetching(false);
        }
      });

    return () => (mounted = false);
  }, [fetching, pages, query, setFetching, setHasMore, PAGE_LIMIT]);

  return { pages, fetching };
};
