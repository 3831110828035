import React from 'react';

import classes from './HealthValueBox.module.scss';

const HealthValueBox = (props) => {
  const { label, value } = props;
  return (
    <div className={classes.container}>
      <h6 className={classes.heading}>{label}</h6>
      <p className={classes.value}>{value}</p>
    </div>
  );
};

export default HealthValueBox;
