import React from 'react';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
import 'react-day-picker/lib/style.css';


import classes from './Input.module.scss';

const Input = (props) => {
  const { label, name, value, isDatePicker, onChange, ...rest } = props;
  const dateFormat = 'ddd, DD MMM YYYY';

 
  return (
    <label className={classes.label} htmlFor={name}>
      {label}
      {isDatePicker ? (
        <DayPickerInput
          value={value}
          formatDate={formatDate}
          onDayChange={(day) => onChange(day)}
          format={dateFormat}
          parseDate={parseDate}
          dayPickerProps={{
            todayButton: 'Today',
          }}
        />
      ) : (
        <input type='text' name={name} value={value} onChange={onChange} {...rest} />
      )}
    </label>
  );
};

export default Input;
