import React from 'react';
import SectionHeading from '../SectionHeading/SectionHeading';
import Project from './Project/Project';
import classes from './Work.module.scss';

const Work = () => {
  const projects = [
    {
      id: 'ust',
      heading: 'Back To School',
      subHeading: 'UST Global',
      img: 'BackToSchoolForPortFolio@2x.png',
      alt: 'Back To School Project',
      customBackground: {},
      summary: [
        {
          paragraph:
            'An initiative by UST for tracking students’ health when schools in America reopened after the Corona Virus pandemic',
        },
      ],
    },
    {
      id: 'fuji',
      heading: 'LMS',
      subHeading: 'Fuji Xerox',
      img: 'FujiXeroxForPortfolio@2x.png',
      alt: 'Fuji Xerox Project',
      customBackground: {
        backgroundImage: 'linear-gradient(to right, #655c78 -100%, #bf3939 79%)',
      },
      summary: [
        {
          paragraph: 'UI Revamp of an existing LMS for employees within Fuji Xerox',
        },
      ],
    },
    {
      id: 'smondo',
      heading: 'Smondo 3',
      subHeading: 'Neotown Township',
      img: 'smondo3@2x.png',
      alt: 'Smondo 3 Neotown',
      customBackground: {
        backgroundImage: 'linear-gradient(to right, rgb(177 177 177) -100%, rgb(196 237 239) 79%)',
      },
      summary: [
        {
          paragraph:
            'The goal of this project was to create a website for Smondo 3, which is an apartment complex in Bangalore. The site’s objective was to give a brief overview (shops, important contacts, etc.) of the area to the residents living th',
        },
      ],
    },
  ];
  return (
    <div id='work' className={`contain ${classes.container}`}>
      <SectionHeading>Work</SectionHeading>
      <div className={classes.projects}>
        <Project projects={projects} />
      </div>
    </div>
  );
};

export default Work;
