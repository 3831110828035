import React from "react";
import classes from "./SectionHeading.module.scss";

const SectionHeading = (props) => {
  return (
    <h3 className={classes.heading} style={props.style}>
      {props.children}
    </h3>
  );
};

export default SectionHeading;
