import React, { useState, useRef, useEffect } from 'react';
import classes from './Navbar.module.scss';
import Logo from '../../Logo/Logo';
import NavLink from './NavLink/NavLink';

const Navbar = () => {
  const [hamburgerClicked, setHamburgerClicked] = useState(false);
  const listRef = useRef(null);

  let listStyle = hamburgerClicked ? `${classes.list} ${classes.slideIn}` : `${classes.list}`;

  const handleClickOutside = (event) => {
    if (listRef.current && !listRef.current.contains(event.target)) {
      setHamburgerClicked(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  const handleHamburgerClick = () => {
    setHamburgerClicked(!hamburgerClicked);
  };

  return (
    <nav className={classes.container}>
      <div className={classes.hamburger} onClick={handleHamburgerClick}>
        <div className={`${classes.hamburgerStick} ${classes.stickTop}`}></div>
        <div className={`${classes.hamburgerStick} ${classes.stickMiddle}`}></div>
        <div className={`${classes.hamburgerStick} ${classes.stickBottom}`}></div>
      </div>
      <ul ref={listRef} className={listStyle}>
        <NavLink
          label='home'
          clicked={() => setHamburgerClicked(false)}
          to='home'
          duration={1000}
        />
        <NavLink
          label='work'
          clicked={() => setHamburgerClicked(false)}
          to='work'
          duration={1000}
        />
        <NavLink
          label='skills'
          clicked={() => setHamburgerClicked(false)}
          to='skills'
          duration={1000}
        />
        <li className={`${classes.listItem} ${classes.logo}`}>
          <Logo />
        </li>
        <NavLink
          label="Voice"
          clicked={() => setHamburgerClicked(false)}
          to="testimonies"
          duration={1000}
        />
        <NavLink
          label="clients"
          clicked={() => setHamburgerClicked(false)}
          to="clients"
          duration={1000}
        />
        <NavLink
          label='contact'
          clicked={() => setHamburgerClicked(false)}
          to='contact'
          duration={1000}
        />
        <Logo class={classes.customPositionedLogo} />
      </ul>
    </nav>
  );
};

export default Navbar;
