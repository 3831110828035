import React, { useState } from 'react';

import moment from 'moment';

import Modal from '../../Modal/Modal';
import AddHealthInfo from '../AddHealthInfo/AddHealthInfo';
import HealthValueBox from '../HealthValueBox/HealthValueBox';

import classes from './HealthCard.module.scss';

const HealthCard = (props) => {
  const { date, sugar, bloodPressure } = props.data;

  const {
    fastingBloodSugar,
    postPrandialBloodSugar,
    beforeLunch,
    afterLunch,
    beforeDinner,
    afterDinner,
  } = sugar;
  const { time, upper, lower, pulse } = bloodPressure;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleEdit = () => setIsModalOpen(true);

  const closeModal = () => setIsModalOpen(false);

  return (
    <div className={classes.container}>
      <button onClick={handleEdit} className={classes.editButton} type='button'>
        Edit
      </button>
      <p className={classes.date}>{moment(new Date(date.toDate())).format('LL')}</p>
      <div className={classes.sugarSection}>
        <h5 className={classes.heading}>Sugar</h5>

        <div className={classes.healthValuesContainer}>
          <HealthValueBox label={'Fbs'} value={fastingBloodSugar ? fastingBloodSugar : '-'} />
          <HealthValueBox
            label={'ppbs'}
            value={postPrandialBloodSugar ? postPrandialBloodSugar : '-'}
          />
          <HealthValueBox label={'bl'} value={beforeLunch ? beforeLunch : '-'} />
          <HealthValueBox label={'al'} value={afterLunch ? afterLunch : '-'} />
          <HealthValueBox label={'bd'} value={beforeDinner ? beforeDinner : '-'} />
          <HealthValueBox label={'ad'} value={afterDinner ? afterDinner : '-'} />
        </div>
      </div>
      <div className={classes.healthSection}>
        <h5 className={classes.heading}>Blood Pressure</h5>

        <div className={classes.healthValuesContainer}>
          <HealthValueBox label={'Time'} value={time ? time : '-'} />
          <HealthValueBox label={'upper'} value={upper ? upper : '-'} />
          <HealthValueBox label={'lower'} value={lower ? lower : '-'} />
          <HealthValueBox label={'pulse'} value={pulse ? pulse : '-'} />
        </div>
      </div>

      {isModalOpen && (
        <Modal closeModalFn={closeModal}>
          <AddHealthInfo heading='Edit' data={props.data} closeModalFn={closeModal} type='edit' />
        </Modal>
      )}
    </div>
  );
};

export default HealthCard;
