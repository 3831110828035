import firebase from 'firebase';
import 'firebase/auth';

var firebaseApp = firebase.initializeApp({
  apiKey: 'AIzaSyBonx0Az9sN0nNqmjX6z4xugBQYEjsnh4I',
  authDomain: 'iamjoelgeorge-9000c.firebaseapp.com',
  databaseURL: 'https://iamjoelgeorge-9000c.firebaseio.com',
  projectId: 'iamjoelgeorge-9000c',
  storageBucket: 'iamjoelgeorge-9000c.appspot.com',
  messagingSenderId: '504476439171',
  appId: '1:504476439171:web:2b956d79a03d7c8e0b41b6',
  measurementId: 'G-KKHHQW0811',
});

const db = firebaseApp.firestore();

export const auth = firebaseApp.auth();
export { db };
