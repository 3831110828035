import React from "react";
import classes from "./Banner.module.scss";
import ProfileButton from "./ProfileButton/ProfileButton";
import Info from "./Info/Info";
import Particles from "react-particles-js";

const Banner = () => {
  return (
    <div className={classes.container}>
      <div className="contain">
        <div className={classes.imgContainer}>
          <img
            src={require("../../../assets/images/banner_img.png")}
            alt="Joel George"
          />
        </div>
        <div className={classes.infoContainer}>
          <div className={classes.particles}>
            <Particles
              params={{
                particles: {
                  number: {
                    value: 2000,
                    density: {
                      enable: true,
                      value_area: 3000,
                    },
                  },
                },
              }}
            />
          </div>
          <Info />
          <ProfileButton />
        </div>
      </div>
    </div>
  );
};

export default Banner;
