import React from "react";
import classes from "./Info.module.scss";

const Info = () => {
  return (
    <p className={classes.info}>
      That
      <br />
      guy
      <br />
      who
      <br />
      makes
      <br />
      websites
    </p>
  );
};

export default Info;
