import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Carousel from 'react-elastic-carousel';

import classes from './Testimonials.module.scss';
import Card from '../Card/Card';
import SectionHeading from '../SectionHeading/SectionHeading';

const Testimonials = () => {
  const testimonies = [
    {
      name: 'Hiow Yim Ong',
      designation: 'APAC L&D Head',
      company: 'Fuji Xerox Asia Pacific',
      testimony:
        'Joel was excellent at  providing programming expertise and design insights to transform our Learning Portal into an intuitive, engaging and user friendly tool that is now widely adopted across all our 13 countries. His detailed understanding of customer needs, quality/timeliness of his deliverables and his strong work ethics further enhanced the value that he added to the project.',
    },
    // {
    //   name: 'Jane Mathew',
    //   designation: 'Software Engineer',
    //   company: 'Google',
    //   testimony:
    //     'Test is a very good word for testing out test scenarios. Isn’t it great that we have a word like test?',
    // },
    // {
    //   name: 'Jack Fruit',
    //   designation: 'Software Engineer',
    //   company: 'Google',
    //   testimony:
    //     'Test is a very good word for testing out test scenarios. Isn’t it great that we have a word like test?',
    // },
  ];

  const breakpoints = [
    { width: 1, itemsToShow: 1 },
    { width: 414, itemsToShow: 1 },
    { width: 640, itemsToShow: 2 },
    { width: 1366, itemsToShow: 3 },
  ];

  return (
    <div id='testimonies' className={`contain ${classes.container}`}>
      <SectionHeading>Testimonials</SectionHeading>
      <div className={classes.testimonies}>
        <Carousel
          breakPoints={breakpoints}
          pagination={false}
          showArrows={false}
          enableAutoPlay={true}
          autoPlaySpeed={3000}
        >
          {testimonies.map((item) => (
            <Card
              key={uuidv4()}
              name={item.name}
              designation={item.designation}
              company={item.company}
              testimony={item.testimony}
              img={item.img}
            />
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default Testimonials;
