import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import classes from './Project.module.scss';
import Summary from '../Summary/Summary';
import Button from '../../Button/Button';

const Project = (props) => {
  return props.projects.map((project) => (
    <div className={classes.container}>
      <div
        key={uuidv4()}
        className={classes.project}
        style={project.customBackground && project.customBackground}
      >
        <img src={require(`../../../assets/images/${project.img}`)} alt={project.alt} />
        <div className={classes.projectDetails}>
          <Summary heading={project.heading} subHeading={project.subHeading}>
            {project.summary.map((item) => (
              <p key={uuidv4()}>{item.paragraph}</p>
            ))}
          </Summary>
          {/* <div className={classes.viewBtnContainer}>
          <Button class={classes.viewBtn}>View</Button>
        </div> */}
        </div>
      </div>
    </div>
  ));
};

export default Project;
