import React from "react";
import classes from "./SocialIcon.module.scss";

const SocialIcons = (props) => {
  return (
    <div className={classes.container}>
      <img
        src={require(`../../assets/images/${props.img}`)}
        alt="social media icon"
      />
    </div>
  );
};

export default SocialIcons;
