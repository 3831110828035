import React from 'react';

import { useAuth } from '../../context/AuthContext';
import classes from './SignOut.module.scss';

const SignOut = (props) => {
  const { signOut } = useAuth();
  
  const handleSignOut = () => {
    signOut();
  };

  return (
    <button onClick={handleSignOut} className={classes.container}>
      Signout
    </button>
  );
};

export default SignOut;
