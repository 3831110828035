import React from "react";
import Overlay from "../../Overlay/Overlay";
import classes from "./Summary.module.scss";

const Summary = (props) => {
  const { heading, subHeading } = props;
  return (
    <div className={classes.container}>
      <h5 className={classes.heading}>{heading}</h5>
      <p className={classes.subHeading}>{subHeading}</p>
      <div className={classes.summary}>{props.children}</div>
      <Overlay />
    </div>
  );
};

export default Summary;
