import React from 'react';

import classes from './LandingPageSpinner.module.scss';

const LandingPageSpinner = () => {
  return (
    <div className={classes.container}>
      <div className={classes.loader}>Loading...</div>
    </div>
  );
};

export default LandingPageSpinner;
