import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth } from '../../context/AuthContext';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import classes from './SignIn.module.scss';

const SignIn = () => {
  const history = useHistory();
  const { signIn } = useAuth();

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const emailRef = useRef();
  const passwordRef = useRef();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const email = emailRef.current.value;
    const password = passwordRef.current.value;

    try {
      setLoading(true);
      setError('');

      await signIn(email, password);
      history.push('/inbox');
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.signInContainer}>
        <form onSubmit={(e) => handleSubmit(e)} className={classes.signInForm}>
          <h3 className={classes.formHeading}>Are you really Joel?</h3>
          <div className={classes.inputContainer}>
            <label htmlFor='email'>
              <input
                className={classes.emailInput}
                placeholder='Email'
                id='email'
                type='text'
                ref={emailRef}
              />
            </label>
            <label htmlFor='password'>
              <input placeholder='Password' id='password' type='password' ref={passwordRef} />
            </label>
          </div>
          <button disabled={loading} className={classes.submitButton} type='submit'>
            {loading ? <LoadingSpinner /> : 'Sign In'}
          </button>
          <p className={classes.error}>{error && error}</p>
        </form>
        <div className={classes.overlay}></div>
      </div>
    </div>
  );
};

export default SignIn;
