import React from "react";
import { Link } from "react-scroll";
import classes from "./NavLink.module.scss";

const NavLink = (props) => {
  const { clicked, to, duration, label } = props;
  return (
    <li className={classes.listItem}>
      <Link onClick={clicked} to={to} smooth={true} duration={duration}>
        {label}
      </Link>
    </li>
  );
};

export default NavLink;
