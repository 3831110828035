import React, { useState } from 'react';

import classes from './HealthPage.module.scss';
import { db } from '../../firebase';
import { useFetchData } from '../../hooks/useFetchData';
import HealthCard from './HealthCard/HealthCard';
import Modal from '../Modal/Modal';
import AddHealthInfo from './AddHealthInfo/AddHealthInfo';

const HealthPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const query = db.collection('health').orderBy('date', 'desc');
  const { pages, fetching } = useFetchData({ query });

  const handleAdd = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => setIsModalOpen(false);

  const renderHealthData = () => {
    return (
      <>{pages.map((page) => page.map((healthData) => <HealthCard key={healthData.date} data={healthData} />))}</>
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.cardsContainer}>{renderHealthData()}</div>
      {fetching && <p>loading...</p>}
      <button type='button' className={classes.addButton} onClick={handleAdd}>
        +
      </button>

      {isModalOpen && (
        <Modal closeModalFn={closeModal}>
          <AddHealthInfo heading='Add' closeModalFn={closeModal} />
        </Modal>
      )}
    </div>
  );
};

export default HealthPage;
