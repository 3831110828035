import React from 'react';

import classes from './PresentationalCard.module.scss';

const PresentationalCard = (props) => {
  const { children, customClass } = props;

  return <div className={`${classes.container} ${customClass}`}>{children}</div>;
};

export default PresentationalCard;
